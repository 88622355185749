<template>
  <div class="english-font">
    <NavBar logger />
    <section class="hero">
      <div class="hero-body">
        <div class="is-size-3 has-text-centered title container">
          <h1 class="title is-size-1 has-text-white">
            COVID-19 Responses
          </h1>
        </div>
      </div>
    </section>
    <div class="container">
      <div class="box header-info-list">
        <span class="icon">
          <i class="fas fa-info-circle"></i>
        </span>
        <h4 class="is-size-5 has-text-white english-font">
          Total responses submitted: {{ totalCountSubmitted }}
        </h4>
        <button :class="[ 'button', 'is-small', 'is-white', refreshClick ? 'is-loading':'' ]"
          @click="getCounts" style="margin: 10px;">
          Refresh
        </button>
      </div>
      <div class="columns is-multiline">
        <div class="column">
          <div class="card dashboard-card">
            <div class="card-header" style="justify-content: center;">
              <div class="has-text-centered">
                <h3 class="has-text-weight-semibold is-size-5 is-centered card-header-title">
                  Extreme Cases- Requires Medical Test & Quarantine
                </h3>
                <h3>Total Cases: {{ casesExt }}</h3>
              </div>
            </div>
            <div class="card-content card-scroll table-container">
              <table class="table is-fullwidth is-hoverable">
                <thead class="is-fixed-top">
                  <tr>
                    <th>Name</th>
                    <th>Number</th>
                    <th>District</th>
                    <th>Called</th>
                  </tr>
                </thead>
                <tbody v-if="!getExtreme">
                  <p class="has-text-dark" style="padding: 20px;">
                    Loading, please wait
                  </p>
                </tbody>
                <tbody v-else >
                  <tr v-for="(person, i) in extreme" :key="i">
                    <th>
                      <a v-on:click="openDialog(person)">{{ person.name }}</a>
                    </th>
                    <td>{{ person.phone }}</td>
                    <td>{{ person.district }}</td>
                    <td>
                      <input
                        type="checkbox"
                        v-model="person.is_called"
                        :id="person.id"
                        @change="check(person.id)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <footer class="card-footer is-centered" style="padding: 4px;">
              <div class="columns" style="width: 100%;">
                <div class="column is-12">
                  <p><v-pagination v-model="currentPageExt" :page-count="totalExt"
                  @change="pagedExt"></v-pagination></p>
                </div>
              </div>
            </footer>
          </div>
        </div>
        <div class="column">
          <div class="card dashboard-card">
            <div class="card-header" style="justify-content: center;">
              <div class="has-text-centered is-centered">
                <h3 class="has-text-weight-semibold is-size-5 is-centered card-header-title">
                  Average Cases- Requires Quarantine Only
                </h3>
                <h3>Total Cases: {{ casesAvg }}</h3>
              </div>
            </div>
            <div class="card-content card-scroll table-container">
              <table class="table is-fullwidth is-hoverable">
                <thead class="is-fixed-top">
                  <tr>
                    <th>Name</th>
                    <th>Number</th>
                    <th>District</th>
                    <th>Called</th>
                  </tr>
                </thead>
                <tbody v-if="!getAverage">
                  <p class="has-text-dark" style="padding: 20px;">
                    Loading, please wait
                  </p>
                </tbody>
                <tbody v-else >
                  <tr v-for="(person, i) in average" :key="i">
                    <th>
                      <a v-on:click="openDialog(person)">{{ person.name }}</a>
                    </th>
                    <td>{{ person.phone }}</td>
                    <td>{{ person.district }}</td>
                    <td>
                      <input
                        type="checkbox"
                        v-model="person.is_called"
                        :id="person.id"
                        @change="check(person.id)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <footer class="card-footer is-centered" style="padding: 4px;">
              <div class="columns" style="width: 100%;">
                <div class="column is-12">
                  <p><v-pagination v-model="currentPageAvg" :page-count="totalAvg"
                  @change="pagedAvg"></v-pagination></p>
                </div>
              </div>
            </footer>
          </div>
        </div>
        <div class="column">
          <div class="card dashboard-card">
            <div class="card-header" style="justify-content: center;">
              <div class="has-text-centered">
                <h3 class="has-text-weight-semibold is-size-5 is-centered card-header-title">
                  Other Cases – Requires normal medical attention
                </h3>
                <h3>Total Cases: {{ casesOth }}</h3>
              </div>
            </div>
            <div class="card-content card-scroll table-container">
              <table class="table is-fullwidth is-hoverable">
                <thead class="is-fixed-top">
                  <tr>
                    <th>Name</th>
                    <th>Number</th>
                    <th>District</th>
                    <th>Called</th>
                  </tr>
                </thead>
                <tbody v-if="!getOther">
                  <p class="has-text-dark" style="padding: 20px;">
                    Loading, please wait
                  </p>
                </tbody>
                <tbody v-else >
                  <tr v-for="(person, i) in other" :key="i">
                    <th>
                      <a v-on:click="openDialog(person)">{{ person.name }}</a>
                    </th>
                    <td>{{ person.phone }}</td>
                    <td>{{ person.district }}</td>
                    <td>
                      <input
                        type="checkbox"
                        v-model="person.is_called"
                        :id="person.id"
                        @change="check(person.id)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <footer class="card-footer is-centered" style="padding: 4px;">
              <div class="columns" style="width: 100%;">
                <div class="column is-12">
                  <p><v-pagination v-model="currentPageOth"
                  :page-count="totalOth" @change="pagedOth"></v-pagination></p>
                </div>
              </div>
            </footer>
          </div>
        </div>
        <div class="column">
          <div class="card dashboard-card">
            <div class="card-header" style="justify-content: center;">
              <div class="has-text-centered">
                <h3 class="has-text-weight-semibold is-size-5 is-centered card-header-title">
                  Normal Case – Requires nothing related to COVID-19
                </h3>
                <h3>Total Cases: {{ casesNrm }}</h3>
              </div>
            </div>
            <div class="card-content card-scroll table-container">
              <table class="table is-fullwidth is-hoverable">
                <thead class="is-fixed-top">
                  <tr>
                    <th>Name</th>
                    <th>Number</th>
                    <th>District</th>
                    <th>Called</th>
                  </tr>
                </thead>
                <tbody v-if="!getNormal">
                  <p class="has-text-dark" style="padding: 20px;">
                    Loading, please wait
                  </p>
                </tbody>
                <tbody v-else >
                  <tr v-for="(person, i) in normal" :key="i">
                    <th>
                      <a v-on:click="openDialog(person)">{{ person.name }}</a>
                    </th>
                    <td>{{ person.phone }}</td>
                    <td>{{ person.district }}</td>
                    <td>
                      <input
                        type="checkbox"
                        v-model="person.is_called"
                        :id="person.id"
                        @change="check(person.id)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <footer class="card-footer" style="padding: 4px;">
              <div class="columns" style="width: 100%;">
                <div class="column is-12">
                  <p><v-pagination class="has-text-centered" v-model="currentPageNrm"
                  :page-count="totalNrm" @change="pagedNrm"></v-pagination></p>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
    <div :class="modal === true ? 'is-active' : ''" class="modal">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title has-text-dark">Case Study</p>
          <button class="delete" aria-label="close" @click="closeModal"></button>
        </header>
        <section v-if="infoLoading" class="modal-card-body">
          <p class="has-text-weight-semibold
          is-size-5 has-text-dark">Name: {{ modalPerson.name }}</p>
          <P class="has-text-weight-semibold
          is-size-5 has-text-dark">District: {{ modalPerson.district }}</P>
          <P class="has-text-weight-semibold
          is-size-5 has-text-dark">Address: {{ modalPerson.address }} , {{ modalPerson.thana }}</P>
          <P class="has-text-weight-semibold
          is-size-5 has-text-dark">Age: {{ age }}</P>
          <P class="has-text-weight-semibold
          is-size-5 has-text-dark">Gender: {{ gender }}</P>
          <P class="has-text-weight-semibold
          is-size-5 has-text-dark">Submitted At: {{ timeDate }}</P>
          <div class="box">
            <P class="has-text-weight-semibold is-size-5 has-text-centered">উত্তর সমূহ</P>
            <div class="columns" v-for="(ques, i) in questions" :key="i">
              <div class="column ques-column">
                <div class="field">
                  <label class="label">{{ ques.id + ". " }} {{ ques.question }}</label>
                  <div class="control has-text-left">
                    <label class="radio" :class="ques.value === '1' ? 'red' : '' " disabled>
                      <input class="is-checkradio" type="radio" disabled
                      value="1" :name="ques.id" v-model="ques.value" />
                      হ্যাঁ
                    </label>
                    <label class="radio" :class="ques.value === '0' ? 'red' : '' " disabled>
                      <input class="is-checkradio" type="radio" disabled
                      value="0" :name="ques.id" v-model="ques.value" />
                      না
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section v-else class="modal-card-body">
          <p class="has-text-weight-semibold is-size-5 has-text-dark">Please Wait, Loading. . .</p>
        </section>
      </div>
    </div>
   <div v-if="calledModal" class="notification is-info">
      Patient Called
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import vPagination from 'vue-plain-pagination';
import NavBar from './parts/Nav.vue';

export default {
  metaInfo() {
    return {
      title: 'Dashboard',
    };
  },
  components: { vPagination, NavBar },
  data() {
    return {
      refreshClick: false,
      totalCountSubmitted: 0,
      calledModal: false,
      showNav: false,
      modal: false,
      modalPerson: {},
      timeDate: '',
      age: '',
      gender: '',
      extreme: [],
      normal: [],
      other: [],
      average: [],
      infoLoading: false,
      currentPageExt: 1,
      totalExt: 0,
      currentPageAvg: 1,
      totalAvg: 0,
      currentPageOth: 1,
      totalOth: 0,
      currentPageNrm: 1,
      totalNrm: 0,
      casesExt: 0,
      casesAvg: 0,
      casesOth: 0,
      casesNrm: 0,
      getExtreme: false,
      getAverage: false,
      getOther: false,
      getNormal: false,
      questions: [
        { // is_feverish [0]
          id: '১',
          question: 'আপনার কি জ্বর আছে বা জ্বরজ্বর অনুভব করছেন?',
          value: '',
        },
        { // has_sore_throat [1]
          id: '২',
          question: 'আপনার কি কাশি বা গলাব্যথা বা দুইটাই আছে?',
          value: '',
        },
        { // has_breathlessness [2]
          id: '৩',
          question: 'আপনার কি শ্বাসকষ্ট আছে বা শ্বাস নিতে বা ফেলতে কষ্ট হচ্ছে?',
          value: '',
        },
        { // high_risk [3]
          id: '৪',
          question: 'আপনি কি ডায়াবেটিস, উচ্চ রক্তচাপ, হার্ট ,কিডনী লিভার বা ফুসফুসের কোন রোগ ( এজমা, সিওপিডি), এইচআইভি (এইডস), ক্যান্সার ইত্যাদি রোগে ভুগছেন?',
          value: '',
        },
        { // is_visited_abroad [4]
          id: '৫',
          question: 'আপনি কি বিগত ১৪ দিনের ভিতরে বিদেশ হতে এসেছেন?',
          value: '',
        },
        { // is_contacted_with_covid [5]
          id: '৬',
          question:
            'আপনি কি বিগত ১৪ দিনের ভিতরে করোনা ভাইরাসে ( কোভিড-১৯) আক্রান্ত এরকম কোন ব্যক্তির সংস্পর্শে এসেছিলেন (একই স্থানে অবস্থান বা ভ্রমন)?',
          value: '',
        },
        { // is_contacted_with_family_who_cough [6]
          id: '৭',
          question:
            'বিগত ১৪ দিনে -জর ,কাশি ,শ্বাসকষ্ট আছে - এমন কারোর সংস্পর্শে কি আপনি এসেছিলেন (পরিবার সদস্য / অফিস  কলিগ)?',
          value: '',
        },
        { // is_healthcare_worker [7]
          id: '৮',
          question:
            'আপনি কি কোনো ধরণের চিকিৎসা, স্বাস্থ্য সেবা অথবা রোগীর পরিচর্যায় নিয়োজিত আছেন?',
          value: '',
        },
        { // has_prescribed_before [8]
          id: '৯',
          question: 'আপনি কি আগে কোনো ডাক্তার দ্বারা করোনার ভাইরাস সম্পর্কিত প্রেসক্রিপশন নিয়েছেন?',
          value: '',
        },
      ],
    };
  },
  mounted() {
    this.getCounts();
    // this.$store
    //   .dispatch('getExtremeCases')
    //   .then((res) => {
    //     this.extreme = res.data.extreme.data;
    //     this.currentPageExt = res.data.extreme.current_page;
    //     this.totalExt = res.data.extreme.last_page;
    //     this.getExtreme = true;
    //   })
    //   .catch(() => {});
    // this.$store
    //   .dispatch('getAverageCases')
    //   .then((res) => {
    //     this.average = res.data.average.data;
    //     this.currentPageAvg = res.data.average.current_page;
    //     this.totalAvg = res.data.average.last_page;
    //     this.getAverage = true;
    //   })
    //   .catch(() => {});
    // this.$store
    //   .dispatch('getNormalCases')
    //   .then((res) => {
    //     this.normal = res.data.normal.data;
    //     this.currentPageNrm = res.data.normal.current_page;
    //     this.totalNrm = res.data.normal.last_page;
    //     this.getNormal = true;
    //   })
    //   .catch(() => {});
    // this.$store
    //   .dispatch('getOtherCases')
    //   .then((res) => {
    //     this.other = res.data.other.data;
    //     this.currentPageOth = res.data.other.current_page;
    //     this.totalOth = res.data.other.last_page;
    //     this.getOther = true;
    //   })
    //   .catch(() => {});
  },
  methods: {
    check(value) {
      this.$store
        .dispatch('calledPerson', value)
        .then(() => {
          this.calledModal = true;
          setTimeout(() => {
            this.calledModal = false;
          }, 5000);
        })
        .catch(() => {});
    },
    openDialog(person) {
      this.modalPerson = person;
      this.modal = true;
      this.$store
        .dispatch('getInfo', person.id)
        .then((res) => {
          const info = Object.values(JSON.parse(res.data.feedback.information));
          for (let i = 0; i < this.questions.length; i += 1) {
            this.questions[i].value = info[i];
          }
          this.age = res.data.feedback.age;
          this.gender = res.data.feedback.gender;
          this.modalPerson.thana = res.data.feedback.thana;
          this.timeDate = dayjs(res.data.feedback.created_at).format('DD/MM/YYYY');
          this.infoLoading = true;
        })
        .catch(() => {});
    },
    // logout() {
    //   this.$store.dispatch('userLogout').then(() => {
    //     this.$router.push({ name: 'login' });
    //   }).catch(() => {
    //     this.$router.push({ name: 'login' });
    //   });
    // },
    closeModal() {
      this.modal = false;
      this.infoLoading = false;
    },
    pagedExt() {
      this.getExtreme = false;
      this.$store
        .dispatch('getPagedExtreme', this.currentPageExt)
        .then((res) => {
          this.extreme = res.data.extreme.data;
          this.currentPageExt = res.data.extreme.current_page;
          this.totalExt = res.data.extreme.last_page;
          this.casesExt = res.data.extreme.total;
          this.getExtreme = true;
        })
        .catch(() => {});
    },
    pagedAvg() {
      this.getAverage = false;
      this.$store
        .dispatch('getPagedAverage', this.currentPageAvg)
        .then((res) => {
          this.average = res.data.average.data;
          this.currentPageAvg = res.data.average.current_page;
          this.totalAvg = res.data.average.last_page;
          this.casesAvg = res.data.average.total;
          this.getAverage = true;
        })
        .catch(() => {});
    },
    pagedOth() {
      this.getOther = false;
      this.$store
        .dispatch('getPagedOther', this.currentPageOth)
        .then((res) => {
          this.other = res.data.other.data;
          this.currentPageOth = res.data.other.current_page;
          this.totalOth = res.data.other.last_page;
          this.casesOth = res.data.other.total;
          this.getOther = true;
        })
        .catch(() => {});
    },
    pagedNrm() {
      this.getNormal = false;
      this.$store
        .dispatch('getPagedNormal', this.currentPageNrm)
        .then((res) => {
          this.normal = res.data.normal.data;
          this.currentPageNrm = res.data.normal.current_page;
          this.totalNrm = res.data.normal.last_page;
          this.casesNrm = res.data.normal.total;
          this.getNormal = true;
        })
        .catch(() => {});
    },
    getCounts() {
      this.refreshClick = true;
      this.$store
        .dispatch('getCounts')
        .then((res) => {
          this.totalCountSubmitted = res.data.totalFeedback;
          this.refreshClick = false;
        })
        .catch(() => { this.refreshClick = false; });
    },
  },
};
</script>
<style>
@media only screen and (max-width: 600px) {
  .notification {
    position: fixed; /* Sit on top of the screen */
    z-index: 1; /* Add a z-index if needed */
    left: 26% !important; /* Center the snackbar */
    bottom: 30px; /* 30px from the bottom */
  }
}
.notification {
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  left: 44%; /* Center the snackbar */
  bottom: 30px; /* 30px from the bottom */
}
.red {
  color: red !important;
}
.navbar-item:hover {
  color: #00d1b2 !important;
}
.navbar-link:hover {
  color: #00d1b2 !important;
}
.navbar-link:not(.is-arrowless)::after {
  border-color: #00d1b2 !important;
}
.table {
  margin-top: 24px;
}
.columns {
  padding: 8px;
}
.table thead th {
  color: white;
  background: #00d1b2;
  position: sticky;
  top: 0;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}
.card-scroll {
  padding-top: 0px;
  height: 400px;
  overflow-y: scroll;
}
.dashboard-card {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.box {
  background: #201b2c !important;
  color: white !important;
  padding-top: 10px;
  margin-top: 10px;
  padding-bottom: 10px  !important;
  margin: 0 !important;
}
.ques-column {
  margin: 10px 0;
}
.header-info-list {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
